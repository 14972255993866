import React from "react";

const HomeTwoFaq = () => {
  return (
    <>
      <section className="faq__area p-relative pt-135 pb-120 grey-bg-12">
        <div
          className="faq__thumb"
          style={{
            background: `url(assets/img/faq/faq-1.jpg)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 offset-xl-7 col-lg-6 offset-lg-6 col-md-7 offset-md-5">
              <div className="faq__wrapper">
                <div
                  className="section__title section__title-3 mb-25 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <span>Insights</span>
                  <h2>Elevate Your Business with Data-Driven Insights</h2>
                </div>

                <div className="accordion" id="accordionExample">
                  <div className="card accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Strategic Consulting Services
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        Unlock your business's full potential with our expert
                        consulting services. We provide strategic insights and
                        solutions tailored to drive your success.
                      </div>
                    </div>
                  </div>

                  <div className="card accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Informed Decisions
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        Empower your business with data-driven insights for
                        confident decision-making. At Vector Tech Sol, we turn
                        complex data into actionable strategies.
                      </div>
                    </div>
                  </div>

                  <div className="card accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Business Success
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        Partner with us to achieve sustainable business growth.
                        Our dedicated services focus on driving your company
                        toward long-term success.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoFaq;
