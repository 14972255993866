import React from "react";
import "./jobs.css";

const HomeTwoJobs = () => {
  return (
    <div className="jobs-main-root">
      <div className="jobs-sub-root">
        <div
          className="section__title section__title-3 mb-25 wow fadeInUp"
          data-wow-delay=".2s"
        >
          <span>Jobs</span>
          <h2>
            Unlock Your Full Potential: Connect with Top Employers and Find a
            Role That Inspires You
          </h2>
        </div>
        <div>
          <embed
            src="https://jobs.vantage360.app/?bg=0D57A6&tenant_id=661d4416931db767d03fbf73&user_id=661d4416931db767d03fbf75&api_key=12345"
            className="embed-screen"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeTwoJobs;
