import React from "react";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeTwoHeader from "../HomeTwo/HomeTwoHeader/HomeTwoHeader";
import HomeTwoFooter from "../HomeTwo/HomeTwoFooter/HomeTwoFooter";
import CommonCtaArea from "../../components/CommonCtaArea/CommonCtaArea";
import HomeTwoJobs from "../HomeTwo/HomeTwoJobs/HomeTwoJobs";

const Jobs = () => {
  return (
    <>
      <PageHelmet pageTitle="Jobs" />
      <HomeTwoHeader />
      <div style={{ marginTop: "80px" }} />
      <HomeTwoJobs />
      <div style={{ marginTop: "40px" }} />
      <CommonCtaArea />
      <HomeTwoFooter />
    </>
  );
};

export default Jobs