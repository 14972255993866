const featuredWorksData = [
  {
    id: 1,
    img: "assets/img/case/case-1.jpg",
    title: "Data-Driven Analytics",
    subtitle:
      "Harness the power of data-driven analytics to transform your business decisions.",
    category: "coaching",
  },
  {
    id: 2,
    img: "assets/img/case/case-2.jpg",
    title: "IT Services Project Management",
    subtitle:
      "Our IT Services Project Management ensures your projects are delivered on time and within budget.",
    category: "facilitation",
  },
  {
    id: 3,
    img: "assets/img/case/case-3.jpg",
    title: "Quality Control",
    subtitle:
      "Ensure the highest standards with our Quality Management Services. We offer a wide range of quality control solutions designed to comply with industry standards and regulations.",
    category: "coaching",
  },
  {
    id: 4,
    img: "assets/img/case/case-4.jpg",
    title: "Recruitment Solutions",
    subtitle: "Based in Austin, Texas, our Recruitment Solutions specialize in matching qualified candidates with the right positions across industries like IT, finance, manufacturing, healthcare, and engineering.",
    category: "consulting",
  },
  {
    id: 5,
    img: "assets/img/case/case-5.jpg",
    title: "Development Services",
    subtitle: "At Vector Tech Sol, we offer a comprehensive range of development services to help you achieve your business objectives.",
    category: "consulting",
  },
  {
    id: 6,
    img: "assets/img/case/case-6.jpg",
    title: "Agile Training Services",
    subtitle: "Our Agile Training Services are designed to equip IT professionals and organizations with the skills needed to excel in Agile project management.",
    category: "consulting",
  },
  
];

export default featuredWorksData;
