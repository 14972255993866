import React from "react";
import { BiLock } from "react-icons/bi";
import { CgArrowLongRight } from "react-icons/cg";
import { FaLightbulb, FaRibbon } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import HomeTwoSingleFeature from "../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature";

const HomeTwoFeatures = () => {
  return (
    <>
      <section className="features__area pt-115 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="features__content-left">
                <div className="section__title section__title-h2 mb-25">
                  <span>SOLUTIONS FOR BUSINESS</span>
                  <h2>
                    Delivering innovative workforce <br /> and HR solutions
                  </h2>
                </div>
                {/* <p>Do one plastered chinwag only a quid squiffy bugger all mate, the little rotter horse play.</p> */}
                <Link to="/" className="z-btn">
                  What we do
                  <i>
                    <CgArrowLongRight />
                  </i>
                </Link>
              </div>
            </div>
            <div className="col-xl-6 offset-xl-1 col-lg-6">
              <div className="features__content-right">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <HomeTwoSingleFeature
                      icon={<FaLightbulb />}
                      title="Talent and expertise"
                      txt="Hire developers who operate as full-time collaborators assembled by matching experts that source world-class talent from over 30 countries. "
                    />
                    <HomeTwoSingleFeature
                      icon={<IoDocumentTextOutline />}
                      title="Flexibility and scalability"
                      txt="We enable you to expand your team on demand, finding top software developers for each project."
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <HomeTwoSingleFeature
                      icon={<FaRibbon />}
                      title="Bulletproof process"
                      txt="With our expertise, proficiency, and flexibility, we make strategic decisions to save time and money, ensuring that every project is a success."
                    />
                    <HomeTwoSingleFeature
                      icon={<BiLock />}
                      title="Time-zone compatibility"
                      txt="Hire Latin American developers who align with U.S. timezones, ensuring seamless communication and real-time collaboration."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoFeatures;
